import { TFunction } from "i18next";

const getErrorMessage = (t : TFunction, key : string, value : string) => {
  switch(key) {
    case 'users.create':
    case 'markets.load':
    case 'markets.delete':
      return {
        title : t(`errors.${value}.title`, '') || t(`errors.${key}.title`, '') || t(`errors.unknown.title`),
        content : t(`errors.${value}.content`, '') || t(`errors.${key}.content`, '') || t(`errors.unknown.content`),
      }
    case 'globalUsers.setCurrentOrganisationId':
      return {
        title : t('errors.globalUsers.setCurrentOrganisationId.title'),
        content : t('errors.globalUsers.setCurrentOrganisationId.content'),
      }
    default:
      return {
        title : t(`errors.unknown.title`),
        content : t(`errors.unknown.content`)
      }
  }
}

export default getErrorMessage;