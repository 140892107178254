import styled from 'styled-components';
import { useAssertAdmin } from '../hooks/use-assert-admin';
import PageLayout from '../components/layout/page-layout';
import { useTranslation } from 'react-i18next';
import Typography from '../components/atoms/typography';
import { useModelStore, useUserStore } from '../store/store';
import { GlobalUser, Market, Organisation, User } from '../store/store-types';
import List from '../components/atoms/list';
import TextButton from '../components/atoms/text-button';
import TrashIcon from '../assets/icons/trash';
import { colors, spacing, typography } from '../styles';
import EditIcon from '../assets/icons/edit';
import MarketModal from '../components/molecules/modals/market-modal';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useModelActions } from '../store/hooks/use-actions';
import HTMLTypography from '../components/atoms/html-typography';
import UnarchiveIcon from '../assets/icons/unarchive';
import ArchiveIcon from '../assets/icons/archive';
import { useAssertSuperAdmin } from '../hooks/use-assert-superadmin';
import { AVAILABLE_FONTS } from '../constants/fonts';
import SelectDropdown from '../components/atoms/select-dropdown';
import {set, get, unset, cloneDeep} from 'lodash';
import axios from 'axios';
import configProvider from '../providers/configprovider';
import ToolButton from '../components/atoms/tool-button';
import PageTopSelector from '../components/atoms/pagetop-selector';
import { useAssertGlobalUser } from '../hooks/use-assert-global-user';
import UserPickerModal from '../components/molecules/global-users/user-picker';
import UserAddIcon from '../assets/icons/user-add';
import EyeIcon from '../assets/icons/eye';
import OrganisationModal from '../components/molecules/modals/organisation-modal';

interface GloablUsersPageProps {

}

const GlobalUsersPage : React.FC<GloablUsersPageProps> = (p) => {
  const allowed = useAssertGlobalUser();
  const {t} = useTranslation();

  const [globalUsers, setGlobalUsers] = useState<GlobalUser[]>([]);
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [selectedTab, setSelectedTab] = useState<'users' | 'organisations'>('organisations');
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showAddOrganisationModal, setShowAddOrganisationModal] = useState(false);
  const {load, add, remove, setCurrentOrganisationId} = useModelActions('globalUsers');
  const {load : loadOrganisations, create} = useModelActions('organisations');
  const {showDialog} = useModelActions('modals');
  const currentOrganisationId = useUserStore(state => state.user?.organisationId);

  useEffect(() => {
    const fetchGlobalUsers = async () => {
      const users = await load();
      setGlobalUsers(users);
    }
    const fetchOrganisations = async () => {
      const organisations = await loadOrganisations();
      setOrganisations(organisations);
    }
    fetchGlobalUsers();
    fetchOrganisations();
  }, []);

  const handleShowAddUser = () => {
    setShowAddUserModal(true);
    }

  const handleAddUser = async (user : User) => {
    await add(user.id);
    const users = await load();
    setGlobalUsers(users);
    setShowAddUserModal(false);
  }

  const handleRemoveUser = async (user : GlobalUser) => {
    const name = user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : user.email;

    showDialog({
      content :<HTMLTypography variant="textRegular" color="text" html={t('pages.globalUsers.removeUserDialogContent', {name})}/>,
      callback : async () => {
        await remove(user.id);
        const users = await load();
        setGlobalUsers(users);
      }
    })
  }

  const handleShowAddOrganisation = () => {
    setShowAddOrganisationModal(true);
  }

  const handleAddOrganisation = async (name : string) => {
    await create(name);
    const organisations = await loadOrganisations();
    setOrganisations(organisations);
    setShowAddOrganisationModal(false);
  }

  const handleJumpToOrganisation = (organisation : Organisation) => {
    if(organisation.id !== currentOrganisationId) {
      setCurrentOrganisationId(organisation.id);
    }
  }

  if(!allowed) {
    return <LoadingContainer>Testing authorization</LoadingContainer>;
  }

  return (
    <PageLayout
      title={t('pages.globalUsers.name')}
      subtitle={t('pages.globalUsers.subtitle')}
      controls={
        selectedTab === 'users' ? 
          <TextButton onClick={handleShowAddUser} color="dark">{t('pages.globalUsers.addUser')}</TextButton> :
          <TextButton onClick={handleShowAddOrganisation} color="dark">{t('pages.globalUsers.addOrganisation')}</TextButton>
      }
    >
      <Container>
      <PageTopSelector
        selected={selectedTab}
        onChange={setSelectedTab}
        options={[{id : 'organisations', label : t('pages.globalUsers.organisations')}, {id : 'users', label : t('pages.globalUsers.users')}]}
      />
      {selectedTab === 'users' && (
        <List<GlobalUser>
          records={globalUsers}
          renderer={(value) => (
            <Typography variant="textBold" color="text">{`${value.firstName} ${value.lastName} <${value.email}>`}</Typography>
          )}
          controls={[
            {icon : (value) => <TrashIcon color={colors.primary}/>, callback : handleRemoveUser, color : 'light'},
          ]}
        />
      )}
      {selectedTab === 'organisations' && (
        <List<Organisation>
          records={organisations}
          renderer={(value) => (
            <span>
              <Typography variant="textBold" color="text">{`${value.name}`}</Typography>
              {currentOrganisationId === value.id && <Typography variant="textRegular" color="text">&nbsp;&nbsp;&nbsp;{`(organisation actuelle)`}</Typography>}
            </span>
          )}
          controls={[
            {icon : (value) => <EyeIcon color={currentOrganisationId !== value.id ? colors.primary : colors.secondaryText}/>, callback : handleJumpToOrganisation, color : 'light'},
          ]}
        />
      )}
      {showAddUserModal && <UserPickerModal onAddUser={handleAddUser} onClose={() => setShowAddUserModal(false)}/>}
      {showAddOrganisationModal && <OrganisationModal onCreate={handleAddOrganisation} onClose={() => setShowAddOrganisationModal(false)}/>}
     </Container>
    </PageLayout>
  )
}

export default GlobalUsersPage;

const Container = styled.div`
  padding-top : ${3*spacing}rem;

`

const LoadingContainer = styled.div`
  position : absolute;
  top : 0;
  left : 0;
  display : flex;
  justify-content : center;
  align-items : center;
  height : 100%;
  width : 100%;
  ${typography.textBold.css}
  color : ${colors.primary};
`

const AddUserButtonContainer = styled.div`
  position : relative;
`

const AddUserMenuContainer = styled.div`
  position : absolute;
  bottom : -2rem;
  right : 0rem;
  transform : translateY(100%);
`

const Row = styled.div`
  display: flex;
`

const PageTitle = styled.div`
  margin-top : 4rem;
`

const SectionTitle = styled.div`
  margin-top : 3rem;
  margin-bottom : 1rem;
`

const Input = styled.input`
  border : none;
  padding : 1rem;
  border-radius : 1rem;
  margin-right : 2rem;
  ${typography.textRegular.css}

  &::placeholder {
    color : ${colors.secondaryText}
  }
`