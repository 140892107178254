import styled from "styled-components";
import { colors, shadows, spacing } from "../styles";
import Typography from "../components/atoms/typography";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ID, NotebookTemplate, Offer, Proposal, ProposalContent, PublicProposal } from "../store/store-types";
import { useModelActions } from "../store/hooks/use-actions";
import { useModelStore } from "../store/store";
import configProvider from "../providers/configprovider";
import ToolButton from "../components/atoms/tool-button";
import ViewIcon from "../assets/icons/view";
import TextButton from "../components/atoms/text-button";
import VideoIcon from "../assets/icons/video";

const PublicPage : React.FC<{}> = (p) => {
  const {t} = useTranslation();
  const {id} = useParams();
  const [proposal, setProposal] = useState<PublicProposal | undefined>();
  const {loadPublic} = useModelActions('proposals');
  const [status, setStatus] = useState<'loading' | 'loaded' | 'expired'>('loading');

  useEffect(() => {
    (async () => {
      if(id) {
        const proposal = await loadPublic(id);
        setProposal(proposal);

        if(new Date(proposal.validUntil).getTime() < Date.now()) {
          setStatus('expired');
        }
        else {
          setStatus('loaded');
        }
      }
    })()
  }, [id])


  const handleViewPdf = async (id : ID) => {
    window.open(
      `${configProvider('PDF_SERVICE_URL')}/generated/${proposal?.id}/${id}/file`,
      '_blank'
    )
  }

  const handleViewAttachment = async (id : ID) => {
    window.open(
      `${configProvider('MODEL_API_URL')}/attached-notebooks/${id}/content.pdf`,
      '_blank'
    )
  }

  const handleViewPrivateAttachment = async (id : ID) => {
    window.open(
      `${configProvider('MODEL_API_URL')}/private-attached-notebooks/${id}/content.pdf`,
      '_blank'
    )
  }

  const handleViewVideo = async (id : ID) => {
    window.open(
      `${configProvider('MODEL_API_URL')}/public-videos/${id}/video`,
      '_blank'
    )
  }

  const handleViewPrivateVideo = async (id : ID) => {
    window.open(
      `${configProvider('MODEL_API_URL')}/private-videos/${id}/video`,
      '_blank'
    )
  }

  const downloadProposal = async () => {
    window.open(
      `${configProvider('MODEL_API_URL')}/public/${id}/proposal.zip`,
      '_blank'
    )
  }

  const notebooks : PublicProposal['notebooks'] = proposal?.notebooks || [];
  const attachments : PublicProposal['attachments'] = proposal?.attachments || [];
  const videos : PublicProposal['videos'] = proposal?.videos || [];
  const privateVideos : PublicProposal['privateVideos'] = proposal?.privateVideos || [];
  const privateAttachments : PublicProposal['privateAttachments'] = proposal?.privateAttachments || [];

  return (
    <Container>
      <Content>
        <Header>
          <HederTitle>
            <Typography variant="bigTitle" color="text">{t('pages.public.title')}</Typography>
            <Typography variant="textRegular" color="secondaryText">{t('pages.public.subtitle')}</Typography>
          </HederTitle>
          {/* <LogoContainer> */}
            {
              proposal?.lid ?
              <LogoContainer>
                <CustomerLogo src={`${configProvider('MODEL_API_URL')}/logos/${proposal.lid}/binary`} />
                {/* <Typography variant="bigTitle" color="tertiaryText">X</Typography> */}
              </LogoContainer> : null
            }
            {/* <EliorLogo src="/ELIOR_Logo_Signat.png" /> */}
          {/* </LogoContainer> */}
        </Header>
        <Chrome>
          <CardsContainer>
            {
              notebooks.map(notebook => (
                <NotebookCardContainer>
                  <ThumbnailContainer>
                    <Thumbnail src={`${configProvider('PDF_SERVICE_URL')}/pages/${notebook.tid}/thumbnail`}/>
                    {
                      status === 'expired' ? null :
                        <ViewToolButton onClick={e => {e.stopPropagation();  e.preventDefault(); handleViewPdf(notebook.id)}}><ViewIcon /></ViewToolButton>
                    }
                  </ThumbnailContainer>
                  <Typography variant="uppercaseTextBold" color="secondaryText">{notebook.name}</Typography>
                </NotebookCardContainer>
              ))
            }
            {
              attachments.map(attachment => (
                <NotebookCardContainer>
                  <ThumbnailContainer>
                    <Thumbnail src={`${configProvider('MODEL_API_URL')}/attached-notebooks/${attachment.id}/thumbnail.png`}/>
                    {
                      status === 'expired' ? null :
                      <ViewToolButton onClick={e => {e.stopPropagation();  e.preventDefault(); handleViewAttachment(attachment.id)}}><ViewIcon /></ViewToolButton>
                    }
                  </ThumbnailContainer>
                  <Typography variant="uppercaseTextBold" color="secondaryText">{attachment.name}</Typography>
                </NotebookCardContainer>
              ))
            }
            {
              privateAttachments.map(attachment => (
                <NotebookCardContainer>
                  <ThumbnailContainer>
                    <Thumbnail src={`${configProvider('MODEL_API_URL')}/private-attached-notebooks/${attachment.id}/thumbnail.png`}/>
                    {
                      status === 'expired' ? null :
                      <ViewToolButton onClick={e => {e.stopPropagation();  e.preventDefault(); handleViewPrivateAttachment(attachment.id)}}><ViewIcon /></ViewToolButton>
                    }
                  </ThumbnailContainer>
                  <Typography variant="uppercaseTextBold" color="secondaryText">{attachment.name}</Typography>
                </NotebookCardContainer>
              ))
            }
            {
              videos.map(video => (
                <NotebookCardContainer>
                  <ThumbnailContainer>
                    <Thumbnail src={`${configProvider('MODEL_API_URL')}/public-videos/${video.id}/thumbnail.png`}/>
                    {
                      status === 'expired' ? null :
                      <ViewToolButton onClick={e => {e.stopPropagation();  e.preventDefault(); handleViewVideo(video.id)}}><VideoIcon /></ViewToolButton>
                    }
                  </ThumbnailContainer>
                  <Typography variant="uppercaseTextBold" color="secondaryText">{video.name}</Typography>
                </NotebookCardContainer>
              ))
            }
            {
              privateVideos.map(video => (
                <NotebookCardContainer>
                  <ThumbnailContainer>
                    <Thumbnail src={`${configProvider('MODEL_API_URL')}/private-videos/${video.id}/thumbnail.png`}/>
                    {
                      status === 'expired' ? null :
                      <ViewToolButton onClick={e => {e.stopPropagation();  e.preventDefault(); handleViewPrivateVideo(video.id)}}><VideoIcon /></ViewToolButton>
                    }
                  </ThumbnailContainer>
                  <Typography variant="uppercaseTextBold" color="secondaryText">{video.name}</Typography>
                </NotebookCardContainer>
              ))
            }
          </CardsContainer>
          <DateContainer>
            <Typography variant="textRegular" color="text">{t('pages.public.validUntil')}</Typography>
            <Typography variant="textBold" color="text">{proposal?.validUntil ?new Date(proposal?.validUntil).toLocaleDateString?.('fr') : '-'}</Typography>
          </DateContainer>
          <Footer>
            <TextButton disabled={status === 'expired'} onClick={downloadProposal} color="dark">{t('pages.public.download')}</TextButton>
          </Footer>
        </Chrome>
      </Content>
    </Container>
  )
}

export default PublicPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.background};
  position: absolute;
  padding : ${5*spacing}rem;
  width : 100%;
  min-height : 100%;
  top : 0;
  left : 0;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const HederTitle = styled.div`
  display: flex;
  flex-direction: column;
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left : ${3*spacing}rem;
  background-color: ${colors.background2};
  border-radius: ${spacing}rem;
  box-shadow: ${shadows.default};
  padding : ${2*spacing}rem;
`

const CustomerLogo = styled.img`
  max-width: 5rem;
  max-height: 4rem;
  /* margin-right : ${spacing}rem; */
`

const EliorLogo = styled.img`
  max-height: 4rem;
  margin-left : ${spacing}rem;
`



const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const Chrome = styled.div`
  margin-top : ${4*spacing}rem;

  background-color: ${colors.background2};
  border-radius: ${spacing}rem;
  box-shadow: ${shadows.default};
  padding : ${2*spacing}rem;
`

const CardsContainer = styled.div`
  display: flex;
  flex-wrap : wrap;

`

const NotebookCardContainer = styled.div`
  display: inline-block;
  margin-right : ${spacing}rem;
  margin-bottom : ${spacing}rem;
`

const ThumbnailContainer = styled.div`
  position : relative;
  margin-right : ${2*spacing}rem;

  &:last-child {
    margin-right : auto;
  }
`
const Thumbnail = styled.img`
  width : 23.4rem;
  height : 13.18rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
`
const ViewToolButton = styled(ToolButton)`
  position : absolute;
  bottom : 1.5rem;
  right : 1rem;
  padding : 0.3rem;
`

const DateContainer = styled.div`
  text-align : right;
  margin-top : ${2*spacing}rem;
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top : ${2*spacing}rem;
`

