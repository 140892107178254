import axios from "axios";


import { GetFunction, SetFunction, ActionsFactory, ModelStore, ID, User, Pagination, ListApiResponse } from "../store-types";
import configProvider from "../../providers/configprovider";

const organisationsActions : ActionsFactory<ModelStore['actions']['organisations'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  load : async () => {
    const response = await axios.get(`${configProvider('MODEL_API_URL')}/organisations?pageSize=1000`);
    return response.data?.data || [];
  },
  
  create : async (name : string) => {
    const response = await axios.post(`${configProvider('MODEL_API_URL')}/organisations`, {name});
    return response.data;
  },
})

export default organisationsActions;