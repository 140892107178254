import { GetFunction, SetFunction, ActionsFactory, ModelStore, ID, SlideIndex, DynamicSlideViewMeta, NotebookContent, PageSlide, TextArea, ProposalContent, NotebookTemplateContent } from "../store-types";
import { Draft, produce } from "immer";
import {v4 as uuid} from 'uuid';


const proposalEditorAction : ActionsFactory<ModelStore['actions']['proposalEditor'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  selectNotebook : (proposalId : ID, notebookId : ID, lastContent : NotebookContent) => {
    set(state => {
      state.proposals.editor.proposalId = proposalId;
      state.proposals.editor.notebookId = notebookId;
      state.proposals.editor.historyPosition = 0;
      state.proposals.editor.history = [lastContent];
      state.proposals.editor.lastSaved = lastContent;
      state.proposals.editor.meta = {};
    })
  },

  moveSlides : (sourceSlideIndex : SlideIndex, targetSlideIndex : SlideIndex, before ?: boolean) => {
    const source = get().actions.proposalEditor.slideAtIndex(sourceSlideIndex) as PageSlide;
    let target : PageSlide = get().actions.proposalEditor.slideAtIndex(targetSlideIndex) as PageSlide;

    // Do nothing if the target and/or source are not valid.
    // Normaly the canDrop method of DND should avoid this.
    if(target.type !== 'page' || source.type !== 'page'|| source.pageId === target.pageId || source.level !== target.level) {return;}

    const level = source.level;

    if(target.linkedGroupdId && target.linkedGroupdId !== source.linkedGroupdId) {
      if(!before) {
        target = ([...(get().actions.proposalEditor.current().slides || [])].reverse().find(s => s.linkedGroupdId === target.linkedGroupdId) || target)  as PageSlide;
      }
      else {
        target = ([...(get().actions.proposalEditor.current().slides || [])].find(s => s.linkedGroupdId === target.linkedGroupdId) || target)  as PageSlide;
      }
    }
    if(target.alternativeGroupId && target.alternativeGroupId !== source.alternativeGroupId) {
      if(!before) {
        target = ([...(get().actions.proposalEditor.current().slides || [])].reverse().find(s => s.alternativeGroupId === target.alternativeGroupId) || target)  as PageSlide;
      }
      else {
        target = ([...(get().actions.proposalEditor.current().slides || [])].find(s => s.alternativeGroupId === target.alternativeGroupId) || target)  as PageSlide;
      }
    }
    if(level < 4 && !before) {
      let i = targetSlideIndex.position + 1;
      let next = get().actions.proposalEditor.slideAtIndex({position : i}) as PageSlide;
      while(next && next.level > level) {
          target = next;
          i++;
          next = get().actions.proposalEditor.slideAtIndex({position : i}) as PageSlide;
        }
    }

    const selectAlternatives = !!source.alternativeGroupId && source.alternativeGroupId !== target.alternativeGroupId;
    const selectLinked       = !!source.linkedGroupdId && source.linkedGroupdId !== target.linkedGroupdId;


    get().actions.proposalEditor.modify(current => {
      const slides = current.slides || [];

      // Sorted list of selected slides
      let endOfBlockFound = false;
      const selectedSlides = slides.filter((slide, index) : slide is PageSlide => {
        if(selectAlternatives && slide.alternativeGroupId === source.alternativeGroupId) {return true;}
        if(selectLinked && slide.linkedGroupdId === source.linkedGroupdId) {return true;}
        if(slide.type !== 'page') {return false;}
        if(index < sourceSlideIndex.position) {return false;}
        if(index === sourceSlideIndex.position) {return true;}

        if(slide.level <= level) {
          endOfBlockFound = true;
          return false;
        }
        if(slide.level > level && !endOfBlockFound) {
          return true
        }

        return false;
      });

      const modifiedSlides = slides.filter(slide => slide.type !== 'page' || selectedSlides.every(s => s.pageId !== slide.pageId));
      const targetIndex = modifiedSlides.findIndex(slide => slide.type === 'page' && slide.pageId === target.pageId);

      modifiedSlides.splice(targetIndex + (before ? 0 : 1), 0, ...selectedSlides);


      current.slides = modifiedSlides;
    })
  },

  modify : (modifier : (content : Draft<NotebookContent>) => void) => {
    if(get().proposals.editor.readonly) {return;}

    set(state => {
      const {history, historyPosition} = state.proposals.editor;
      const current = history[historyPosition];

      const newContent = produce(current, modifier);

      state.proposals.editor.history = [newContent, ...history.slice(historyPosition)];
      if(historyPosition) {
        state.proposals.editor.historyPosition = 0;
      }
    })
  },

  setLabel : (slideIndex : SlideIndex, label : string) => {
    get().actions.proposalEditor.modify((current) => {
      const slides = current.slides || [];

      const slide = slides[slideIndex.position];
      slide.label = label;
    });
  },

  setCustomText : (slideIndex : SlideIndex, content : string) => {
    get().actions.proposalEditor.modify((current) => {
      const slides = current.slides || [];

      const slide = slides[slideIndex.position];

      if(slide.textArea) {
        slide.textArea.content = content;
      }
    });
  },

  replaceSlide : async (slideIndex: SlideIndex, originalPage : PageSlide) => {
    const newPageId : ID = (await get().actions.pages.clone([originalPage.pageId]))[0];

    get().actions.proposalEditor.modify((current) => {
      const slides = [...current.slides || []];
      const slide = slides[slideIndex.position] as PageSlide;

      if(slide.type === 'page') {
        slide.pageId = newPageId;
        slide.textArea = originalPage.textArea;
        slide.label = originalPage.label;
      }
    })
  },

  insertSlide : async (slideIndex: SlideIndex, originalPages : PageSlide[], before ?: boolean) => {
    let target : PageSlide = get().actions.proposalEditor.slideAtIndex(slideIndex) as PageSlide;

    // Do nothing if the target and/or source are not valid.
    // Normaly the canDrop method of DND should avoid this.
    if(target.type !== 'page') {return;}

    const level = target.level;

    const newPagesId : ID[] = await get().actions.pages.clone(originalPages.map(p => p.pageId))

    const alternativeGroupsIds : Record<string, string> = originalPages.reduce((cumul, page) => {
      if(page.alternativeGroupId) {
        cumul[page.alternativeGroupId] = uuid();
      }
      return cumul;
    }, {} as Record<string, string>);
    const linkedGroupdIds : Record<string, string> = originalPages.reduce((cumul, page) => {
      if(page.linkedGroupdId) {
        cumul[page.linkedGroupdId] = uuid();
      }
      return cumul;
    }, {} as Record<string, string>);

    const pages : PageSlide[] = newPagesId.map((pageId, index) => ({
      ...originalPages[index],
      pageId,
      linkedGroupdId : level === 4 ? (originalPages[index].linkedGroupdId && linkedGroupdIds[originalPages[index].linkedGroupdId as string]) : undefined,
      alternativeGroupId : originalPages[index].alternativeGroupId && alternativeGroupsIds[originalPages[index].alternativeGroupId as string],
    }));


    if(target.linkedGroupdId) {
      if(!before) {
        target = ([...(get().actions.proposalEditor.current().slides || [])].reverse().find(s => s.linkedGroupdId === target.linkedGroupdId) || target)  as PageSlide;
      }
      else {
        target = ([...(get().actions.proposalEditor.current().slides || [])].find(s => s.linkedGroupdId === target.linkedGroupdId) || target)  as PageSlide;
      }
    }
    if(target.alternativeGroupId) {
      if(!before) {
        target = ([...(get().actions.proposalEditor.current().slides || [])].reverse().find(s => s.alternativeGroupId === target.alternativeGroupId) || target)  as PageSlide;
      }
      else {
        target = ([...(get().actions.proposalEditor.current().slides || [])].find(s => s.alternativeGroupId === target.alternativeGroupId) || target)  as PageSlide;
      }
    }
    if(level < 4 && !before) {
      let i = slideIndex.position + 1;
      let next = get().actions.proposalEditor.slideAtIndex({position : i}) as PageSlide;
      while(next && next.level > level) {
          target = next;
          i++;
          next = get().actions.proposalEditor.slideAtIndex({position : i}) as PageSlide;
        }
    }

    get().actions.proposalEditor.modify(current => {
      const slides = current.slides || [];

      const modifiedSlides = [...slides];
      const targetIndex = modifiedSlides.findIndex(slide => slide.type === 'page' && slide.pageId === target.pageId);

      modifiedSlides.splice(targetIndex + (before ? 0 : 1), 0, ...pages.map(page => ({
        ...page,
        level
      })));

      current.slides = modifiedSlides;
    })
  },

  toggleCollapse : (pageId : ID) => {
    set(state => {
      const current = state.actions.proposalEditor.current();
      const alternativeGroupId = (current.slides || []).find(slide => slide.type === 'page' && slide.pageId === pageId)?.alternativeGroupId;
      const collapsed = !state.proposals.editor.meta[pageId]?.collapsed;

      if(alternativeGroupId) {
        (current.slides || []).filter((s) : s is PageSlide => s.type === 'page' && s.alternativeGroupId === alternativeGroupId).forEach(slide => {
          state.proposals.editor.meta[slide.pageId] = {...state.proposals.editor.meta[slide.pageId], collapsed}
        })
      }
      else {
        state.proposals.editor.meta[pageId] = {...state.proposals.editor.meta[pageId], collapsed}
      }
    })
  },

  toggleSelection : (pageId : ID) => {
    const current = get().actions.proposalEditor.current();
    const slide : PageSlide | undefined = current.slides?.find(
      slide => slide.type === 'page' && slide.pageId === pageId
    ) as PageSlide | undefined;
    const mandatory = slide?.mandatory || slide?.isSummary
    const multichoice = !!current.multichoiceSlidesGroups?.[slide?.alternativeGroupId || '']

    if(mandatory && current.selection?.[pageId]) {
      return;
    }

    get().actions.proposalEditor.modify((current) => {
      const value = !current.selection?.[pageId];



      let pagesIds : ID[] = [pageId];
      const unselectedPagesIds : ID[] = [];

      // Select parents slides when a child is selected
      if(value && slide?.level && slide.level > 1) {
        let currentLevel = slide.level;
        let index = current.slides?.findIndex(s => s.type === 'page' && s.pageId === pageId) || -1;

        while(index >= 0 && currentLevel > 1) {
          const currentSlide = (current.slides || [])[index];

          if(currentSlide && currentSlide.type === 'page' && currentSlide.level < currentLevel) {
            pagesIds.push(currentSlide.pageId);
            currentLevel = currentSlide.level;
          }
          index--;
        }
      }

      if(slide?.linkedGroupdId) {
        pagesIds = (current.slides || []).filter((s) : s is PageSlide => s.type === 'page' && s.linkedGroupdId === slide.linkedGroupdId).map(s => s.pageId);
      }
      else if (slide?.alternativeGroupId && !multichoice) {
        // Deselect all other groups slides if !multichoice
        (current.slides || [])
          .filter((s) : s is PageSlide => s.type === 'page' && !!s.alternativeGroupId && slide.alternativeGroupId === s?.alternativeGroupId)
          .forEach(slide => {
            current.selection = {
              ...current.selection,
              [slide.pageId] :  false
            }
          });
      }

      pagesIds.forEach(id => {
        current.selection = {
          ...current.selection,
          [id] :  value
        }
      })
    })
  },

  select : (pageId : ID | ID[]) => {
    get().actions.proposalEditor.modify((current) => {
      pageId = Array.isArray(pageId) ? pageId : [pageId];

      current.selection = pageId.reduce((cumul, next) => {
        cumul[next] = true;
        return cumul;
      }, {} as Record<string, boolean>);
    })
  },

  addToSelection : (pageId : ID) => {
    get().actions.proposalEditor.modify((current) => {
      current.selection = {
        ...current.selection,
        [pageId] : true
      }
    })
  },

  addRangeToSelection : (pageId : ID) => {
    get().actions.proposalEditor.modify((current) => {
      const notebookId = get().proposals.editor.notebookId;
      const template = get().notebooks.templatesContent[notebookId || 0]

      const slides = template?.slides || [];
      const selection = current.selection;
      const first = slides.findIndex(slide => slide.type === 'page' && selection?.[slide.pageId]);
      const last  = slides.findLastIndex(slide => slide.type === 'page' && selection?.[slide.pageId]);
      const target = slides.findIndex(slide => slide.type === 'page' && slide.pageId === pageId);

      // If there is no selection, then just select something
      if(first === -1) {
        current.selection = {[pageId] : true};
        return;
      }

      let from : number, to : number;
      if(first < target) {
        from = first;
        to   = target;
      }
      else {
        from = target;
        to   = last;
      }

      const newSelection : Record<string, boolean> = {};
      slides.forEach((slide, index) => {
        if(slide.type === 'page' && index >= from && index <= to) {
          newSelection[slide.pageId] = true;
        }
      })

      current.selection = newSelection;
    })
  },

  deselect : (pageId : ID) => {
    const slide : PageSlide | undefined = get().actions.proposalEditor.current().slides?.find(
      slide => slide.type === 'page' && slide.pageId === pageId
    ) as PageSlide | undefined;
    const mandatory = slide?.mandatory || slide?.isSummary

    if(mandatory) {
      return;
    }

    get().actions.proposalEditor.modify((current) => {
      current.selection = {
        ...current.selection,
        [pageId] : false
      }
    })
  },

  isSectionSelected : (slideIndex : SlideIndex) => {
    const current = get().actions.proposalEditor.current();
    const slides = current?.slides || [];
    const selection = current?.selection || {};

    if(!slides || !slides.length) {return false;}

    const level = slides[slideIndex.position].level;

    // Selected alternatives id
    const alternativesIds : string[] = Object.entries(selection)
    // keep only selected slides
    .filter(([_, value]) => !!value)
    // keep only PageSlides
    .map(([pageId]) => current.slides?.find(s => s.type === 'page' && s.pageId == parseInt(pageId)))
    .filter((s) : s is PageSlide => !!s && s.type === 'page')
    // keep only slides that have radio type alternative group id
    .filter((slide) => {
      const alternativeGroupId = slide?.alternativeGroupId;
      const multichoice = current.multichoiceSlidesGroups?.[alternativeGroupId || ''];

      return alternativeGroupId && !multichoice;
    })
    .map(s => s.alternativeGroupId || '');


    for(let i = slideIndex.position; i < slides.length && (i == slideIndex.position || slides[i].level > level); i++) {
      const slide = slides[i];
      if(slide.type === 'page') {
        const pageId = slide.pageId;
        if(!selection[pageId] && !(slide.alternativeGroupId && alternativesIds.includes(slide.alternativeGroupId))) {
          return false;
        }
      }
    }

    return true;
  },

  selectSection : (slideIndex : SlideIndex) => {
    get().actions.proposalEditor.modify((current) => {
      const slides = current?.slides || [];
      const selection = current?.selection || {};

      const level = slides[slideIndex.position].level;

      const alternativesIds : string[] = Object.entries(selection)
      // keep only selected slides
      .filter(([_, value]) => !!value)
      // keep only PageSlides
      .map(([pageId]) => current.slides?.find(s => s.type === 'page' && s.pageId == parseInt(pageId)))
      .filter((s) : s is PageSlide => !!s && s.type === 'page')
      // keep only slides that have radio type alternative group id
      .filter((slide) => {
        const alternativeGroupId = slide?.alternativeGroupId;
        const multichoice = current.multichoiceSlidesGroups?.[alternativeGroupId || ''];

        return alternativeGroupId && !multichoice;
      })
      .map(s => s.alternativeGroupId || '');

      for(let i = slideIndex.position; i < slides.length && (i == slideIndex.position || slides[i].level > level); i++) {
        const slide = slides[i];
        if(slide.type === 'page') {
          if(slide.alternativeGroupId && !current.multichoiceSlidesGroups?.[slide.alternativeGroupId]) {
            if(!alternativesIds.includes(slide.alternativeGroupId)) {
              alternativesIds.push(slide.alternativeGroupId)
            }
            else {
              continue;
            }
          }

          const pageId = slide.pageId;
          selection[pageId] = true;
        }
      }
    })
  },

  deselectSection : (slideIndex : SlideIndex) => {
    get().actions.proposalEditor.modify((current) => {
      const slides = current?.slides || [];
      const selection = current?.selection || {};

      const level = slides[slideIndex.position].level;

      for(let i = slideIndex.position; i < slides.length && (i == slideIndex.position || slides[i].level > level); i++) {
        const slide = slides[i];
        if(slide.type === 'page' && !slide.mandatory && !slide.isSummary) {
          const pageId = slide.pageId;
          selection[pageId] = false;
        }
      }
    })
  },


  undo : () => {
    if(get().proposals.editor.readonly) {return;}

    set(state => {
      const {history, historyPosition} = state.proposals.editor;
      if(historyPosition < history.length - 1) {
        state.proposals.editor.historyPosition++;
      }
    })
  },
  redo : () => {
    if(get().proposals.editor.readonly) {return;}

    set(state => {
      const {historyPosition} = state.proposals.editor;
      if(historyPosition > 0) {
        state.proposals.editor.historyPosition--;
      }
    })
  },

  save : async () => {
    if(get().proposals.editor.readonly) {return;}

    const current = get().actions.proposalEditor.current();
    const notebookId = get().proposals.editor.notebookId;
    const proposalId = get().proposals.editor.proposalId;
    if(notebookId && proposalId) {
      set(state => {state.proposals.editor.readonly = true});

      await get().actions.proposals.saveNotebookContent(proposalId, notebookId, current)
      .then(() => {
        set(state => {state.proposals.editor.lastSaved = current})
      })
      .finally(() => {
        set(state => {state.proposals.editor.readonly = false});
      })

      const content = await get().actions.proposals.loadContent(proposalId);
      const modifiedContent : ProposalContent = {
        ...content,
        notebooksUpdatedAt : {
          ...content.notebooksUpdatedAt,
          [notebookId] : Date.now()
        }
      }

      await get().actions.proposals.saveContent(proposalId, modifiedContent);
    }
  },

  current : () => {
    const state = get();
    const {history, historyPosition} = state.proposals.editor;
    const current = history[historyPosition];
    return current;
  },

  slideAtIndex : (slideIndex : SlideIndex) => {
    const current = get().actions.proposalEditor.current();
    const slides = current.slides || [];

    const slide = slides[slideIndex.position];
    // if(slideIndex.isAlternative && slide.alternatives) {
    //   return slide.alternatives[slideIndex.alternativePosition];
    // }
    // else {
      return slide;
    // }
  },

  /**
   * Slides metadata used to show/hide collapsed slides, show links
   * between slides etc.
   * This is necessary beacause data is inerently a tree but we decided
   * to store it as a flat array to ease insert/move/delete operations.
   */
  viewMeta : () => {
    const current = get().actions.proposalEditor.current();
    const meta = get().proposals.editor.meta;
    if(!current) {return [];}

    const tags = get().proposals.editor.tags;
    const tagsByFilter = get().proposals.editor.tagsByFilter;

    const notebookId = get().proposals.editor.notebookId;
    const template = get().notebooks.templatesContent[notebookId || 0]

    // const slides = template?.slides || [];
    const slides = current.slides || [];

    // Creates an array of empty meta. Using map to create different objects. Do not use fill,
    // otherwise the same object will be referenced.
    const result : DynamicSlideViewMeta[] = new Array(slides.length || 0).fill(null).map(() => ({}));

    // Last collapsed level. Starts at 10, so that slide[].level < levelOfLastCollapsedSlide, which
    // means that nothing is collapsed.
    let levelOfLastCollapsedSlide : number = 10;

    // Array of bolleans that indicate when a given slide should show a link from a predecessor to a successor
    let slideLinksLevels : boolean[] = [];

    // Store the id of the alternative groups already rendered
    // Only the first slide of an alternative group is rendered
    const renderedAlternativesUuid : Record<string, boolean> = {};


    let positionInContentGroup : number = -1;
    let lastLinkedGroupdId : string | undefined;

    slides.forEach((slide, index) => {
      if(slides[index+1] && slides[index+1].level > slide.level) {
        result[index].hasChildren = true;
      }

      /*
      Handle level collapse by keeping track of the last collapsed level in levelOfLastCollapsedSlide var
      */
      const collapsed = slide.type === 'page' && !!meta[slide.pageId]?.collapsed;
      if(levelOfLastCollapsedSlide > slide.level) {
        if(collapsed) {
          /*
          We are in a lower level than the last collapsted slide. Since we are collapsed
          bring levelOfLastCollapsedSlide down to our level to hide all slides lower that the current one
           */
          levelOfLastCollapsedSlide = slide.level
        }
      }
      else if(levelOfLastCollapsedSlide === slide.level) {
        if(!collapsed) {
          /*
            If the last collapsed level was on the same level as the current slide, and
            the current slide is not collapsed, then push levelOfLastCollapsedSlide away to show the slides
            below.
           */
          levelOfLastCollapsedSlide = 10;
        }
      }
      else {
        /*
          In this case we are in a level higher than the active hideLeve, so we should be hidden
         */
        result[index].hidden = true;
      }

      /*
        Use filters to hide slides
      */
      if(tags.length > 0 && !Object.values(tagsByFilter).every(tags => tags.some(t => (slide.tags || []).includes(t)))) {
        result[index].hidden = true;
      }

      // Keep only links at level lower than the current one. Set higher level to false
      slideLinksLevels = slideLinksLevels.map((v,i) => i < slide.level && !!v);

      result[index].link1 = tags.length ? false : !!slideLinksLevels[1];
      result[index].link2 = tags.length ? false : !!slideLinksLevels[2];
      result[index].link3 = tags.length ? false : !!slideLinksLevels[3];
      result[index].selfLink = !tags.length;

      // Iterate through following slides to determine links level
      if(slide.level < 4) {
        let next = index + 1;
        let lowest = 4;
        while(next < slides.length) {
          if(slides[next].level >= slide.level && slides[next].level < lowest) {
            slideLinksLevels[slides[next].level] = true;
            lowest = slides[next].level;
          }

          if(slides[next].level <= slide.level) {
            break
          }

          next++;
        }
      }

      // If slide is part of group of alternative slides...
      if(slide.alternativeGroupId) {
        // ... and is not first one of the group, then just hide it
        if(renderedAlternativesUuid[slide.alternativeGroupId]) {
          result[index].hiddenAlternative = true;
        }
        // ... otherwise, for the first slide, count the number of alternatives
        else {
          renderedAlternativesUuid[slide.alternativeGroupId] = true;
          result[index].alternativesCount = slides.filter(_s => _s.alternativeGroupId === slide.alternativeGroupId).length;
        }

        // Update hasChildren for grouped slides
        const nextSlide = slides[slides.findLastIndex(s => s.alternativeGroupId === slide.alternativeGroupId) + 1];
        if(nextSlide && nextSlide.level > slide.level) {
          result[index].hasChildren = true;
        }
      }

      if(slide.level === 4) {positionInContentGroup++;}
      else {positionInContentGroup = -1;}

      if(slide.level === 4 && slide.linkedGroupdId) {
        result[index].linkedLeftStyle = slide.linkedGroupdId !== lastLinkedGroupdId ? 'none' : 'middle'; //positionInContentGroup % 4 === 0 ? 'first' : 'middle';
        result[index].linkedRightStyle = slide.linkedGroupdId !== slides[index + 1]?.linkedGroupdId ? 'none' : 'middle'; //positionInContentGroup % 4 === 3 ? 'last' : 'middle';
        lastLinkedGroupdId = slide.linkedGroupdId;
      }
      else {
        lastLinkedGroupdId = undefined;
      }
    })

    return result;
  },

  isModified : () => {
    const state = get();
    return state.actions.proposalEditor.current() !== state.proposals.editor.lastSaved;
  },

  prefilter : async (tags : ID[], notebookIds : number[]) => {
    const current = get().actions.proposalEditor.current();
    const notebookId = get().proposals.editor.notebookId;

    if(current.prefiltered || !notebookId) {
      return;
    }

    const template : Required<NotebookTemplateContent> = {
      uploadIds : [],
      slides : [],
      multichoiceSlidesGroups : {}
    };

    notebookIds.forEach(nid => {
      const _template = get().notebooks.templatesContent[nid];
      if(_template) {
        template.uploadIds = [...template.uploadIds, ...(_template.uploadIds ||[])];
        template.slides = [...template.slides, ...(_template.slides || [])];
        template.multichoiceSlidesGroups = {...template.multichoiceSlidesGroups, ..._template.multichoiceSlidesGroups};
      }
    })

    const alreadySelectedGroupesSlides : Record<string, boolean> = {};

    const selection : PageSlide[] = [];

    (template?.slides || []).forEach(
      (slide) => {
        if(slide.type === 'page' && (
          (slide.tags || []).some(tagId => tags.includes(tagId)) ||
          slide.mandatory ||
          slide.isSummary
          ) &&
            !alreadySelectedGroupesSlides[slide.alternativeGroupId || '-']
        ) {
          selection.push(slide);

          if(slide.alternativeGroupId && !current.multichoiceSlidesGroups?.[slide.alternativeGroupId]) {
            alreadySelectedGroupesSlides[slide.alternativeGroupId] = true;
          }
        }
      }
    );

    // Add all linked pages. This algorigthm creates duplicate entries in selection array, but this is not a problem since it will
    // be deduped in reduce below.
    const linkedGroupdIds = selection.filter(slide => slide.linkedGroupdId).map(slide => slide.linkedGroupdId);
    linkedGroupdIds.forEach(
      linkedGroupdId => (template?.slides || [])
        .filter((slide) : slide is PageSlide => slide.type == 'page' && slide.linkedGroupdId === linkedGroupdId)
        .forEach(slide => selection.push(slide))
      );

    const notebookContent : NotebookContent = {
      ...template,
      selection : selection.map(page => page.pageId).reduce((cumul, next) => {cumul[next] = true; return cumul;}, {} as Record<string, boolean>),
      prefiltered : true,
    }

    set(state => {
      state.proposals.editor.historyPosition = 0;
      state.proposals.editor.history = [notebookContent];
      state.proposals.editor.lastSaved = notebookContent;
    })

    await get().actions.proposalEditor.save();
  },

  setTags : (tags : ID[]) => {
    set(state => {state.proposals.editor.tags = tags})
  },

  setTagsByFilter : (tags : Record<ID, ID[]>) => {
    set(state => {state.proposals.editor.tagsByFilter = tags})
  },

  validateNotebook : () => {
    const current = get().actions.proposalEditor.current();
    const selection = current.selection || {};
    const slides = current.slides || [];
    const selected = slides.filter((slide) : slide is PageSlide => slide.type == 'page' && selection[slide.pageId]);

    if(!selected.length) {
      return {
        error : true,
        type : 'empty'
      }
    }

    const missingTextSlideIndex = slides.findIndex(slide => slide.type === 'page' && selection[slide.pageId] && slide.textArea && !slide.textArea.content);
    if(missingTextSlideIndex !== -1) {
      return {
        error : true,
        type : 'missing-text',
        position : {position : missingTextSlideIndex},
        slide : slides[missingTextSlideIndex] as PageSlide
      }
    }

    return {error : false};
  }
})

export default proposalEditorAction;