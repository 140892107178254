import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer'
import { persist } from "zustand/middleware";
import deepmerge from 'deepmerge';
import axios from 'axios';

import {ModelStore, UserStore} from './store-types';
import userActions from './actions/user-actions';
import marketsActions from './actions/markets-actions';
import notebooksActions from './actions/notebooks-actions';
import rtActions from './actions/rt-actions';
import notebooksEditorActions from './actions/notebooks-editor-actions';
import uploadActions from './actions/upload-actions';
import modalsActions from './actions/modals-actions';
import usersActions from './actions/users-actions';
import regionsActions from './actions/regions-actions';
import filtersActions from './actions/filters-actions';
import offersActions from './actions/offers-actions';
import proposalActions from './actions/proposal-actions';
import proposalEditorAction from './actions/proposal-editor-actions';
import customersActions from './actions/customers-actions';
import attachmentsActions from './actions/attachments-actions';
import videosActions from './actions/videos-actions.ts';
import pagesActions from './actions/pages-actions';
import globalUsersActions from './actions/global-users-actions';
import organisationsActions from './actions/organisations-actions';

export const useUserStore = create<UserStore>()(
  // persist(
    immer<UserStore>(
      (set, get) => ({
        user : {
          logged : false
        },
        process : {
          clearError : (key : string) => {set(state => {delete state.process.errors[key];})},
          loading : {},
          errors  : {}
        },
        actions : {
          user : userActions(set, get)
        }
      })
    )/*, {
      name : 'agami-user',
      merge: (persistedState, currentState) => deepmerge(currentState, persistedState as Partial<UserStore>),
      onRehydrateStorage : (state) => (state, error) => {
        if(!state) {return;}

        /*
          Configure axios with current token when rehydrating from localStorage
        /
        if(state.user.logged && state.user.token) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${state.user.token}`;
        }
      }
    }*/
  // )
)

export const useModelStore = create<ModelStore>()(
  immer<ModelStore>(
    (set, get) => ({
      markets : [],
      regions : [],
      filters : [],
      uploads : {},
      attachments : [],
      privateAttachments : [],
      videos: [],
      privateVideos : [],
      process : {
        clearError : (key : string) => {set(state => {delete state.process.errors[key];})},
        loading : {},
        errors : {},
        progress : {},
        modals : {
          colorPicker : {},
          dialog : {},
          contextMenu : {}
        }
      },
      notebooks : {
        templates : {},
        templatesContent : {},
        pages : {},
        editor : {
          history : [],
          historyPosition : 0,
          meta : {},
          selection : {}
        }
      },
      proposals : {
        generated : {},
        editor : {
          history : [],
          historyPosition : 0,
          meta : {},
          selection : {},
          tags : [],
          tagsByFilter : {}
        }
      },
      actions : {
        organisations : organisationsActions(set, get),
        globalUsers : globalUsersActions(set, get),
        modals : modalsActions(set, get),
        regions : regionsActions(set, get),
        filters : filtersActions(set, get),
        attachments : attachmentsActions(set, get),
        videos : videosActions(set, get),
        users : usersActions(set, get),
        markets : marketsActions(set, get),
        customers : customersActions(set, get),
        uploads : uploadActions(set, get),
        pages : pagesActions(set, get),
        notebooks : notebooksActions(set, get),
        notebookEditor : notebooksEditorActions(set, get),
        offers : offersActions(set, get),
        proposals : proposalActions(set, get),
        proposalEditor : proposalEditorAction(set, get),
        rt : rtActions(set, get)
      },
      init : async () => {
        const state = get();

        await Promise.all([
          state.actions.markets.load(),
          state.actions.regions.load(),
          state.actions.filters.load()
        ])
      }
    })
  )
)
