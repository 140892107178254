import { useTranslation } from "react-i18next";
import Modal from "../modal";
import { colors, spacing, typography } from "../../../styles";
import styled from "styled-components";
import { useModelStore, useUserStore } from "../../../store/store";
import { useState } from "react";
import TextButton from "../../atoms/text-button";
import MarketIcon from "../../../assets/icons/markets";
import CustomersIcon from "../../../assets/icons/customers";

interface OrganisationModalProps {
  onClose : () => void;
  onCreate : (name : string) => void;
}

const OrganisationModal : React.FC<OrganisationModalProps> = (p) => {
  const {t} = useTranslation();
  const [editedName, setEditedName] = useState<string | undefined>('');

  const handleSubmit = () => {
    if(editedName?.trim()) {
      p.onCreate(editedName.trim());
      p.onClose();
    }
  }

  return (
    <Modal
      title={t('pages.globalUsers.addOrganisatinModalTitle')}
      subtitle={t('pages.globalUsers.addOrganisatinModalSubtitle')}
      icon={<CustomersIcon color={colors.text}/>}
      onClose={p.onClose}
      onSubmit={handleSubmit}
      controls={[
        <TextButton key="validate" type="submit" disabled={!editedName?.trim()}>{t('validate')}</TextButton>
      ]}
    >
      <InputsContainer>
        <NameInput
          placeholder={t('pages.globalUsers.organisationNamePlaceholder')}
          value={editedName}
          onChange={e => setEditedName(e.target.value)}
        />
      </InputsContainer>
    </Modal>
  );
}

export default OrganisationModal;

const InputsContainer = styled.div`
  min-width : 50rem;
  margin-top: ${2*spacing}rem;
  background-color: ${colors.background};
  border-radius: ${spacing}rem;
  padding: ${spacing}rem;
  display: flex;
  flex-direction: column;
`


const NameInput = styled.input`
  color : ${colors.text};
  &::placeholder {
    color : ${colors.tertiaryText};
  }
  ${typography.bigTitle.css};
  border : none;
  background-color: transparent;
  outline: none;
`


