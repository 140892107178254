import axios from "axios";


import { GetFunction, SetFunction, ActionsFactory, ModelStore, ID, User, Pagination, ListApiResponse } from "../store-types";
import configProvider from "../../providers/configprovider";
import { setError } from "../helpers/set-error";

const globalUsersActions : ActionsFactory<ModelStore['actions']['globalUsers'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  load : async () => {
    const response = await axios.get(`${configProvider('MODEL_API_URL')}/global-users?pageSize=1000`);
    return response.data?.data || [];
  },
  add : async (userId : ID) => {
    const response = await axios.post(`${configProvider('MODEL_API_URL')}/global-users`, {userId});
    return response.data;
  },
  remove : async (id : ID) => {
    const response = await axios.delete(`${configProvider('MODEL_API_URL')}/global-users/${id}`);
    return response.data;
  },
  setCurrentOrganisationId : async (organisationId : ID) => {
    try {
      const response = await axios.post(`${configProvider('MODEL_API_URL')}/global-users/current-organisation`, {organisationId});
      window.location.reload();
    }
    catch(error) {
      console.error(error);
      setError(set, 'globalUsers.setCurrentOrganisationId', 'errors.globalUsers.setCurrentOrganisationId')
    }
  }
})

export default globalUsersActions;