import { useEffect, useState } from "react";
import styled from "styled-components"
import Typography from "../../atoms/typography";
import { useTranslation } from "react-i18next";
import { colors, shadows, spacing, typography } from "../../../styles";
import ArrowDownIcon from "../../../assets/icons/arrow-down";
import { useModelActions } from "../../../store/hooks/use-actions";
import { ID, User } from "../../../store/store-types";
import useDebounce from "../../../hooks/use-debounce";
import PlusInCircleIcon from "../../../assets/icons/plus-in-circle";
import { getColor, getFirstLetter } from "../share-picker/tools";
import Modal from "../modal";
import UserIcon from "../../../assets/icons/user";
import TextButton from "../../atoms/text-button";

interface UserPickerModalProps {
  onClose : () => void;
  onAddUser : (user : User) => void;
}


const UserPickerModal : React.FC<UserPickerModalProps> = (p) => {
  const {t} = useTranslation();
  const [editedQuery, setEditedQuery] = useState<string>('');
  const {search} = useModelActions('users');
  const [users, setUsers] = useState<User[] | null>(null);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const {debounce} = useDebounce(async (value ?: string) => {
    if(value) {
      search(value).then(setUsers);
    }
    else {
      setUsers([]);
    }
  }, 400)


  const handleChangeQuery = (query : string) => {
    setEditedQuery(query);
    setUsers(null);
    debounce(query);
  }


  const handleSelectUser = (user : User) => {
    setSelectedUser(user);
    setEditedQuery('');
    setUsers(null);
  }

  const handleCreateUser = () => {
    if(selectedUser) {
      p.onAddUser(selectedUser);
      setSelectedUser(null);
    }
  }


  return (
    <Modal
      onClose={p.onClose}
      title={t('pages.globalUsers.modalTitle')}
      subtitle={t('pages.globalUsers.modalSubtitle')}
      icon={<UserIcon color={colors.text}/>}
      controls={[
        <TextButton key="save" disabled={!selectedUser} onClick={handleCreateUser}>{t('pages.globalUsers.addUser')}</TextButton>
      ]}
    >
      <Container>
        <Input
          placeholder={t('pages.proposal.shareDialogPlaceholder')}
          value={selectedUser ? selectedUser.email : editedQuery}
          onChange={e => handleChangeQuery(e.target.value)}
          disabled={!!selectedUser}
        />
        {
          users ?
            <SelectionDropdown>
              {
                editedQuery && users && users.length === 0 ?
                  <NoUserFoundContainer>
                    <Typography variant="textBold">{t('pages.users.noUserFound')}</Typography>
                  </NoUserFoundContainer> :
                users && users.length ?
                users.map(user => (
                  <SelectionItem key={user.id} onClick={() => handleSelectUser(user)}>
                    <Typography variant="textBold">{user.firstName} {user.lastName}</Typography> <Typography variant="textRegular">&lt;{user.email}&gt;</Typography>
                  </SelectionItem>
                )) : null
              }
            </SelectionDropdown> : null
        }
        {
          selectedUser && 
            <span onClick={() => setSelectedUser(null)}>
              <DeleteUserIcon width="1.8rem" height="1.8rem" />
            </span>
        }
      </Container>
    </Modal>
  )
}

export default UserPickerModal;

const Container = styled.div`
  position : relative;
  padding : ${spacing}rem;
  user-select: none;
`

const Header = styled.div`
  margin-bottom : ${spacing}rem;
`






const Input = styled.input`
  background-color : ${colors.background};
  min-width : 35rem;
  border-radius : ${spacing}rem;
  border : none;
  outline: none;
  padding: ${spacing}rem;
  ${typography.textRegular.css};
  &::placeholder {
    color : ${colors.secondaryText}
  }
  margin-bottom : ${spacing}rem;
`

const SelectionDropdown = styled.div`
  position : absolute;
  border-radius : 1.5rem;
  box-shadow: ${shadows.heavy};
  background-color: ${colors.background2};
  padding : ${spacing}rem;
  padding-left : 0;
  padding-right : 0;
  z-index: 10;
  bottom: 0;
  transform: translateY(100%);
`

const SelectionItem = styled.div`
  padding : 0.7rem;
  padding-left : ${spacing}rem;
  padding-right : ${spacing}rem;
  cursor : pointer;

  &:hover {
    background-color: ${colors.primary};
  }

  &:hover span{
    color: ${colors.whiteText};
  }
`

const NoUserFoundContainer = styled.div`
  padding : 0.7rem;
  padding-left : ${spacing}rem;
  padding-right : ${spacing}rem;
`
const UserChip = styled.div`
  display: inline-flex;
  align-items: center;
  padding : 0.7rem;
  border-radius : 2rem;
  box-shadow: ${shadows.default};
  margin-right : ${spacing}rem;
  margin-bottom : ${spacing}rem;
`

const DeleteUserIcon = styled(PlusInCircleIcon)`
  transform: rotate(45deg);
  margin-left : 0.7rem;
`

const UserLetter = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width : 1.8rem;
  height : 1.8rem;
  border-radius : 1rem;
  margin-right : 0.7rem;
`
