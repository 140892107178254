import axios from "axios";


import { GetFunction, SetFunction, ActionsFactory, ModelStore, ID, User, Pagination, ListApiResponse, Offer, Proposal, NotebookContent, ProposalContent } from "../store-types";
import configProvider from "../../providers/configprovider";
import { setLoading } from "../helpers/set-loading";
import { setError } from "../helpers/set-error";
import { useUserStore } from "../store";
import { ADMIN, SUPERADMIN, USER } from "../../constants/roles";
import { PAGE_SIZE } from "../../constants/api";



const proposalActions : ActionsFactory<ModelStore['actions']['proposals'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  load : async (page : number, query ?: {draft ?: boolean, query ?: string, creatorId ?: ID, after ?: Date, regionsIds ?: ID[]}) => {
    setLoading(set, 'proposals.load', true);

    try {
      let params : string = '';
      if(query?.query?.trim()) {
        params += `&q=${encodeURIComponent(query.query.trim())};name`
      }
      if(typeof query?.draft === 'boolean') {
        params += query?.draft ? '&draft=true' : '&draft=false';
      }
      if(query?.creatorId) {
        params += `&qor=-${query?.creatorId}-;sharedWith`
      }
      if(query?.after) {
        params += `&f=sentAt;ge;${query.after.toISOString()}`
      }
      if(query?.regionsIds) {
        params += query.regionsIds.map(rid => `&regionsIds=${rid}`).join('')
      }

      const proposalsResponse = await axios.get(`${configProvider('MODEL_API_URL')}/proposals?pageSize=${query?.after ? 10000 : PAGE_SIZE}&page=${page}${params}&sort=sentAt&order=DESC`);

      if(proposalsResponse.status === 200) {
        const data : ListApiResponse<Proposal> = proposalsResponse.data || {};

        setLoading(set, 'proposals.load', false);

        return {
          ...data,
          data : data.data.map(proposal => ({
            ...proposal,
          }))
        };
      }
    }
    catch(error) {}

    setError(set, 'proposals.load', 'errors.proposals.load')
    throw "error.proposals.load";
  },

  loadOne : async(id : ID) => {
    setLoading(set, 'proposals.loadOne', true);

    try {
      const proposalResponse = await axios.get<Proposal>(`${configProvider('MODEL_API_URL')}/proposals/${id}`);

      if(proposalResponse.status === 200) {
        setLoading(set, 'proposals.loadOne', false);

        return {
          ...proposalResponse.data,
        }
      }
    }
    catch(error) {}

    setError(set, 'proposals.loadOne', 'errors.proposals.loadOne')
    throw "error.proposals.loadOne";
  },

  create : async (proposal : Partial<Proposal>) => {
    setLoading(set, 'proposals.create', true);

    if(!proposal.name) {
      setError(set, 'proposals.create', 'proposals.create.missingParams');
      throw 'proposals.create.missingParams';
    }

    try {
      const proposalResponse = await axios.post<Proposal>(`${configProvider('MODEL_API_URL')}/proposals`, {
        name : proposal.name,
        draft : typeof proposal.draft === 'boolean' ? proposal.draft : true,
        offerId : proposal.offerId,
        marketId : proposal.marketId
      });

      if(proposalResponse.status === 200) {
        const newProposal : Proposal = proposalResponse.data;

        setLoading(set, 'proposals.create', false);

        return newProposal;
      }
    }
    catch(error) {}

    setError(set, 'proposals.create', 'errors.proposals.create')
    throw 'errors.proposals.create'
  },

  delete : async (proposalId : ID) => {
    setLoading(set, 'proposals.delete', true);

    try {
      const proposalResponse = await axios.delete(`${configProvider('MODEL_API_URL')}/proposals/${proposalId}`);

      if(proposalResponse.status === 200) {
        setLoading(set, 'proposals.delete', false);

        return;
      }

      if(proposalResponse.status === 409) {
        setError(set, 'proposals.delete', 'proposals.deleteConflict')
        return;
      }
    }
    catch(error) {}

    setError(set, 'proposals.delete', 'proposals.delete')
    throw 'proposals.delete'
  },

  update : async (proposalId : ID, patch : Partial<Proposal>) => {
    setLoading(set, 'proposals.update', true);

    try {
      const proposalResponse = await axios.patch<Proposal>(`${configProvider('MODEL_API_URL')}/proposals/${proposalId}`, patch);

      if(proposalResponse.status === 200) {

        setLoading(set, 'proposals.update', false);

        return {
          ...proposalResponse.data,
        }
      }
    }
    catch(error) {}

    setError(set, 'proposals.update', 'proposals.update')
    throw 'proposals.update'
  },

  clearGeneration : async (proposalId : ID, notebooksIds : ID[]) => {
    setLoading(set, 'proposals.clearGeneration', true);

    try {
      for(let notebookId of notebooksIds) {
        if(get().proposals.generated[`${proposalId}-${notebookId}`]) {
          await axios.patch(`${configProvider('PDF_SERVICE_URL')}/generated/${proposalId}/${notebookId}`, {status : 'none'});
          set(state => {
            state.proposals.generated[`${proposalId}-${notebookId}`] = false;
            delete state.process.progress[`generation-${proposalId}-${notebookId}`]
          })
        }
      }
    }
    catch(error) {
      console.error(error);
      setError(set, 'proposals.clearGeneration', 'proposals.clearGeneration')
      throw 'proposals.clearGeneration'
    }
  },

  clone : async (proposalId : ID) => {
    setLoading(set, 'proposals.clone', true);

    try {
      const originalProposal = await get().actions.proposals.loadOne(proposalId);
      const newProposal = await get().actions.proposals.create({
        draft : true,
        name : originalProposal.name + '*',
        offerId : originalProposal.offerId,
        marketId : originalProposal.marketId,
        regionsIds : originalProposal.regionsIds,
        customerId : originalProposal.customerId,
      })
      const content = await get().actions.proposals.loadContent(proposalId);

      const newProposalContent = {...content};

      // Change id of notebook in modification timestamp registry
      if(content.notebooksUpdatedAt?.[-proposalId]) {
        newProposalContent.notebooksUpdatedAt = {...content.notebooksUpdatedAt};
        newProposalContent.notebooksUpdatedAt[-newProposal.id] = content.notebooksUpdatedAt[-proposalId];
        delete newProposalContent.notebooksUpdatedAt[-proposalId];
      }

      await get().actions.proposals.saveContent(newProposal.id, newProposalContent);


      const mainNotebookContent = await get().actions.proposals.loadNotebookContent(proposalId, -proposalId);
      await get().actions.proposals.saveNotebookContent(newProposal.id, -newProposal.id, mainNotebookContent);

      for(let notebookId of (content.selectedNotebooksIds || [])) {
        const notebookContent = await get().actions.proposals.loadNotebookContent(proposalId, notebookId);
        await get().actions.proposals.saveNotebookContent(newProposal.id, notebookId, notebookContent);
      }

      setLoading(set, 'proposals.clone', false);
      return newProposal;
    }
    catch(error) {}

    setError(set, 'proposals.clone', 'proposals.clone')
    throw 'proposals.clone'
  },

  loadContent : async (proposalId : ID) => {
    setLoading(set, 'proposals.loadContent', true);

    try {
      const response = await axios.get(`${configProvider('MODEL_API_URL')}/proposals/${proposalId}/content`);

      if(response.status === 200) {
        const content : ProposalContent = response.data;

        setLoading(set, 'proposals.loadContent', false);
        return content;
      }
    }
    catch(error) {
      console.error(error)
    }

    setError(set, 'proposals.loadContent', 'errors.proposals.loadContent')
    throw 'errors.proposals.loadContent';
  },

  saveContent : async (proposalId : ID, content : ProposalContent) => {
    setLoading(set, 'proposals.saveContent', true);

    try {
      const response = await axios.put(`${configProvider('MODEL_API_URL')}/proposals/${proposalId}/content`, content);

      if(response.status === 200) {
        setLoading(set, 'proposals.saveContent', false);
        return response.data;
      }
    }
    catch(error) {}

    setError(set, 'proposals.saveContent', 'errors.proposals.saveContent')
    throw 'errors.proposals.saveContent';
  },


  loadNotebookContent : async (proposalId : ID, notebookId : ID) => {
    setLoading(set, 'loadNotebookContent', true);

    try {
      const response = await axios.get(`${configProvider('MODEL_API_URL')}/proposals/${proposalId}/${notebookId}`);

      if(response.status === 200) {
        const content : NotebookContent = response.data;

        setLoading(set, 'loadNotebookContent', false);
        return content;
      }
    }
    catch(error) {
      console.error(error)
    }

    setError(set, 'loadNotebookContent', 'errors.loadNotebookContent')
    throw 'errors.loadNotebookContent';
  },

  saveNotebookContent : async (proposalId : ID, notebookId : ID, content : NotebookContent) => {
    setLoading(set, 'saveNotebookContent', true);

    try {
      if(get().proposals.generated[`${proposalId}-${notebookId}`]) {
        await axios.patch(`${configProvider('PDF_SERVICE_URL')}/generated/${proposalId}/${notebookId}`, {status : 'none'});
        set(state => {
          state.proposals.generated[`${proposalId}-${notebookId}`] = false;
          delete state.process.progress[`generation-${proposalId}-${notebookId}`]
        })
      }

      const response = await axios.put(`${configProvider('MODEL_API_URL')}/proposals/${proposalId}/${notebookId}`, content);

      if(response.status === 200) {
        setLoading(set, 'saveNotebookContent', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'saveNotebookContent', 'errors.saveNotebookContent')
    throw 'errors.saveNotebookContent';
  },

  generateNotebook : async (proposalId : ID, notebookId: ID) => {
    setLoading(set, 'proposals.generateNotebook', true);

    try {
      const taskResponse = await axios.post(`${configProvider('PDF_SERVICE_URL')}/tasks`, {
        meta : [{
          key : 'id',
          value : `${proposalId}`
        }, {
          key : 'nid',
          value : `${notebookId}`
        }],
        active : true,
        status : 'generate-notebook',
        priority : 1
      });

      if(taskResponse.status === 200) {
        const taskId = taskResponse.data.id;

        set(state => {
          state.process.progress[`generation-${proposalId}-${notebookId}`] = {
            progress : 1,
            total : 100
          }
          state.proposals.generated[`${proposalId}-${notebookId}`] = false;
          delete state.process.progress[`generation-${proposalId}-${notebookId}`]
        })

        setLoading(set, 'proposals.generateNotebook', false);
        return taskId;
      }
    }
    catch(error) {}

    setError(set, 'proposals.generateNotebook', 'errors.proposals.generateNotebook')
    throw 'errors.proposals.generateNotebook';
  },

  updateGenerationStatus : async (proposalId : ID) => {
    setLoading(set, 'proposals.updateGenerationStatus', true);

    try {
      const generatedResponse = await axios.get(`${configProvider('PDF_SERVICE_URL')}/generated?proposalId=${proposalId}&pageSize=100`);

      if(generatedResponse.status === 200) {
        const documents = generatedResponse.data.data;

        if(Array.isArray(documents)) {
          set(state => {
            documents.forEach(document => {
              if(document.status === 'done') {
                state.proposals.generated[`${document.proposalId}-${document.notebookId}`] = true;
              }
            })
          })
        }

        setLoading(set, 'proposals.updateGenerationStatus', false);

        return;
      }
    }
    catch(error) {}

    setError(set, 'proposals.updateGenerationStatus', 'errors.proposals.updateGenerationStatus')
    throw 'errors.proposals.updateGenerationStatus';

  },

  loadPublic : async (uuid : string) => {
    setLoading(set, 'proposals.loadPublic', true);

    try {
      const publicResponse = await axios.get(`${configProvider('MODEL_API_URL')}/public/${uuid}`);

      if(publicResponse.status === 200) {
        const document = publicResponse.data;

        setLoading(set, 'proposals.loadPublic', false);

        return document;
      }
    }
    catch(error) {}

    setError(set, 'proposals.loadPublic', 'errors.proposals.loadPublic')
    throw 'errors.proposals.loadPublic';
  }
})

export default proposalActions;