import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUserStore } from "../store/store"
import { SUPERADMIN } from "../constants/roles";
import { DASHBOARD, HOME } from "../constants/routes";
import { useUserActions } from "../store/hooks/use-actions";
import axios from "axios";
import configProvider from "../providers/configprovider";

export const useAssertGlobalUser = () => {
  const logged = useUserStore(state => state.user.logged);
  const role = useUserStore(state => state.user.role);
  const userId = useUserStore(state => state.user.id);
  const navigate = useNavigate(); 
  const {storeLoginRedirect} = useUserActions('user');
  const location = useLocation();

  const [allowed, setAllowed] = useState(false);


  useEffect(() => {
    if(!logged) {
      storeLoginRedirect(location.pathname);
      navigate(HOME);
    }
    else if (role !== SUPERADMIN) {
      navigate(DASHBOARD);
    }

    if(userId) {
      axios.get(`${configProvider('MODEL_API_URL')}/global-users?userId=${userId}`).then(({data}) => {
        if(!data?.data?.length) {
          navigate(DASHBOARD);
        }
        else {
          setAllowed(true);
        }
      })
    }

  }, [logged, role, userId])

  return allowed;
}
